<template>
  <!-- 安徽 -->
  <div>
    <pdf-viewer :url="url"></pdf-viewer>
  </div>
</template>

<script>
import PdfViewer from "@/components/PdfViewer.vue";

export default {
  components: {
    PdfViewer,
  },
  data: function () {
    return {
      url: "",
      code: null,
    };
  },
  created() {
    this.code = this.$route.query.code;
    this.getCode();
  },
  methods: {
    getCode() {
      let data = {
        fwm: this.code,
      };
      // 转换对象为查询字符串
      const urlParams = new URLSearchParams(data);
      // const url = `https://admin.atqf.cn/prod-api/atqf/shbxjfzm/fwm?${data}`;
      const url = `https://admin.atqf.cn/prod-api/atqf/ahsShbxjfzm/fwm?${urlParams.toString()}`;
      const options = {
        method: "GET", // 请求方法
        headers: {
          "Content-Type": "application/json", // 设置请求头
        },
      };

      fetch(url, options)
        .then((response) => response.json())
        .then((res) => {
          this.url = res.data.path;
        })
        .catch((error) => console.error("Error:", error));

      return;
    },
  },
};
</script>
