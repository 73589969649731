<template>
  <div>
    <div
      v-if="isIosPic == true"
      style="background-color: #ededed; min-height: 100vh; padding-top: 25%"
    >
      <el-card>
        <img
          style="width: 100%; height: 100%"
          src="https://atqf.oss-cn-beijing.aliyuncs.com/file/2024-08-07/1111.jpg"
          alt=""
        />
      </el-card>
    </div>
    <el-dialog
      :visible.sync="wxdialogVisible"
      :show-close="false"
      :close-on-click-modal="false"
      width="350px"
      center
      style="margin-top: 30%"
    >
      <div
        style="
          color: #111;
          font-size: 18px;
          width: 70%;
          text-align: center;
          margin: 0 auto;
          font-family: 微软雅黑;
        "
      >
        下载：{{ pdfName }}
      </div>
      <div>
        <div
          style="
            text-align: center;
            border-top: 1px solid #e6e6e6;
            margin-top: 35px;
            cursor: pointer;
            font-size: 18px;
            display: flex;
            align-items: center;
          "
        >
          <div
            style="
              flex: 1;
              color: #000;
              border-right: 1px solid #e6e6e6;
              padding: 15px 0;
            "
            @click="wxdialogVisible = false"
          >
            取消
          </div>
          <div style="flex: 1; color: #5b6e98" @click="wxSubmit">确定</div>
        </div>
      </div>
    </el-dialog>
  </div>
</template>
<script>
export default {
  data() {
    return {
      isAndroid: false,
      isiOS: false,
      wxdialogVisible: false,
      pdfUrl: "",
      pdfName: "个人工伤保险缴费明细表.pdf",
      iosPic: "",
      isIosPic: false,
      isWeChat: false,
      code: null,
    };
  },
  created() {
    this.getUrlCode("code");
    // this.getPdf();
    this.detectDevice();
  },

  methods: {
    getUrlCode(variable) {
      var query = window.location.href.substring(1);
      var vars = query.split("?");
      for (var i = 0; i < vars.length; i++) {
        var pair = vars[i].split("=");
        if (pair[0] == variable) {
          this.code = pair[1];
        }
      }
    },
    detectDevice() {
      const userAgent = navigator.userAgent || navigator.vendor || window.opera;

      // 判断 Android
      this.isAndroid =
        userAgent.indexOf("Android") > -1 || userAgent.indexOf("Adr") > -1;

      // 判断 iOS
      this.isiOS = /iPad|iPhone|iPod/.test(userAgent) && !window.MSStream;

      const userAgents = navigator.userAgent.toLowerCase();
      this.isWeChat = /micromessenger/.test(userAgents);

      if (this.isAndroid != true && this.isWeChat == true) {
        this.getPdf();
        this.wxdialogVisible = true;
        return;
      }
      if (this.isiOS == true && this.isWeChat != true) {
        // this.wxdialogVisible = true;
        this.getPdf();
        window.location.href = this.pdfUrl;
        return;
      }

      if (this.isAndroid == true) {
        this.getPdf();
        window.location.href = this.pdfUrl;
        return;
      }
    },
    wxSubmit() {
      this.wxdialogVisible = false;
      this.getPdf();

      setTimeout(() => {
        this.isIosPic = true;
        document.title = "黄泽嘉养老.pdf";
        this.iosPic =
          "https://atqf.oss-cn-beijing.aliyuncs.com/file/2024-08-07/养老.jpg";
      }, 2000);
    },

    getPdf() {
      this.pdfUrl =
        "https://atqf.oss-cn-beijing.aliyuncs.com/file/2024-07-24/83ec26f6a94c4e0290e42493ee9df36e.pdf";

      // return;

      // let data = {
      //   fwm: this.code,
      // };
      // console.log(data, "================");

      // // // 转换对象为查询字符串
      // const urlParams = new URLSearchParams(data);
      // // // const url = `https://admin.atqf.cn/prod-api/atqf/shbxjfzm/fwm?${data}`;
      // const url = `https://admin.atqf.cn/prod-api/atqf/shbxjfzm/fwm?${urlParams.toString()}`;
      // const options = {
      //   method: "GET", // 请求方法
      //   headers: {
      //     "Content-Type": "application/json", // 设置请求
      //   },
      // };

      // fetch(url, options)
      //   .then((response) => response.json())
      //   .then((res) => {
      //     console.log(res, "-================");

      //     // this.iosPic =
      //     // this.pdfUrl = res.data.path;
      //     // window.location.href = res.data.path;
      //   })
      //   .catch((error) => console.error("Error:", error));

      return;
    },
  },
};
</script>
<style scoped>
::v-deep .el-dialog__header {
  padding: 0 !important;
}
::v-deep .el-dialog--center .el-dialog__body {
  padding: 20px 0 0 0 !important;
}
</style>
