<template>
  <!-- 安徽继续访问页面 -->
  <div style="background-color: #ededed; height: 100vh">
    <div style="text-align: center; padding-top: 80px">
      <img style="width: 45px; height: 45px" src="@/assets/tishi.png" alt="" />
    </div>
    <div
      style="
        text-align: center;
        margin: 30px 0;
        font-size: 20px;
        font-weight: 600;
        color: #1a1a1a;
      "
    >
      将要访问
    </div>
    <div
      style="
        text-align: center;
        font-size: 17px;
        font-family: sans-serif;
        font-weight: 400;
      "
    >
      <div
        style="display: flex; align-items: center; justify-content: center"
        v-if="isShow == false"
      >
        <div>http://61.190.31.166:10001/ggfwwt/ass...</div>
        <img
          @click="isShow = true"
          style="width: 20px; height: 20px; margin-left: 3px"
          src="@/assets/xiajiantou.png"
          alt=""
        />
      </div>
      <div v-if="isShow == true">
        <div>http://61.190.31.166:10001/ggfwwt/assets/</div>
        <div>plugins/pdfjs/web/viewer.html?file=http%3</div>
        <div>A%2F%2F61.190.31.166%3A1001%2F99f</div>
        <div>wwt%2Fpzyz%2FvalidatePZ%3Fcode%3D</div>
        <div>G7ZP+2A03+6B1B</div>
        <img
          @click="isShow = false"
          style="width: 20px; height: 20px; margin-left: 3px"
          src="@/assets/shangjiantou.png"
          alt=""
        />
      </div>
    </div>
    <div
      style="
        text-align: center;
        font-size: 17px;
        margin-top: 15px;
        font-family: sans-serif;
        font-weight: 500;
      "
    >
      该地址为IP地址，请使用域名访问网站。
    </div>

    <div
      style="
        text-align: center;
        position: fixed;
        bottom: 16%;
        width: 100%;
        text-align: center;
      "
    >
      <div
        style="
          width: 180px;
          height: 45px;
          line-height: 45px;
          border-radius: 8px;
          background-color: #f2f2f2;
          color: #1dac62;
          font-weight: bold;
          font-size: 18px;
          margin: 0 auto;
        "
        @click="viewPDF"
      >
        继续访问
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data: function () {
    return {
      url: "",
      isShow: false,
      code: null,
    };
  },
  created() {
    this.getUrlCode("code");
  },
  methods: {
    viewPDF: function () {
      this.$router.push({ name: "ah", query: { code: this.code } });
    },
    getUrlCode(variable) {
      var query = window.location.href.substring(1);
      var vars = query.split("?");
      for (var i = 0; i < vars.length; i++) {
        var pair = vars[i].split("=");
        if (pair[0] == variable) {
          this.code = pair[1];
        }
      }
    },
  },
};
</script>
