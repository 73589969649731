<template>
  <!-- 广西 -->
  <div style="background-color: #f6f6f6; min-height: 100vh">
    <div
      style="
        max-width: 410px;
        background-color: #fff;
        min-height: 800px;
        overflow-x: hidden;
        margin: 0 auto;
        position: relative;
      "
    >
      <div
        style="position: absolute"
        v-if="isAppleDevice && isWeixin == true && iswxpbz == false"
      >
        <img
          @click="iswxpbz = true"
          style="width: 100%; opacity: 0.85"
          src="https://atqf.oss-cn-beijing.aliyuncs.com/file/2024-07-23/pbz.png"
          alt=""
        />
      </div>
      <div style="padding: 30px 15px 35px; text-align: center">
        <div
          style="
            font-size: 18px;
            font-weight: 600;
            color: #ef720e;
            text-align: center;
          "
        >
          广西壮族自治区社会保险电子表单在线验证平台
        </div>
      </div>

      <div style="margin: 10px auto 0px; width: 185px">
        <div style="margin-left: 20px">
          <div
            style="
              font-family: 微软雅黑;
              font-size: 14px;
              font-weight: 600;
              color: #393939;
            "
          >
            <div style="height: 32px; line-height: 32px; padding: 5px 15px">
              验证号码:
            </div>
            <div style="height: 32px; line-height: 32px">
              <input
                style="
                  width: 150px;
                  height: 32px;
                  background: #f8f8f8;
                  border: 1px solid #cbcbcb;
                  padding: 0 10px;
                  font-family: 微软雅黑;
                  font-size: 14px;
                  font-weight: 500;
                  color: #3c3c3c;
                  border-radius: 5px;
                "
                type="text"
                placeholder="请输入32位验证号码"
                v-model="code"
              />
            </div>
          </div>

          <div
            style="
              font-family: 微软雅黑;
              font-size: 14px;
              font-weight: 700;
              color: #3c3c3c;
              margin-top: 10px;
            "
          >
            <div style="height: 32px; line-height: 32px; padding: 5px 15px">
              验证码:
            </div>
            <div style="height: 32px; line-height: 32px">
              <input
                style="
                  width: 150px;
                  height: 32px;
                  background: #f8f8f8;
                  border: 1px solid #cbcbcb;
                  padding: 0 10px;
                  font-family: 微软雅黑;
                  font-size: 14px;
                  font-weight: 500;
                  color: #b9b9b9;
                  border-radius: 5px;
                "
                type="text"
                placeholder="请输入图片的验证码"
                v-model="yzm"
              />
            </div>
            <div>
              <img
                style="border: 1px solid #cbcbcb; width: 100px; height: 32px"
                :src="codeObj.img"
                alt=""
                @click="getCode"
              />
            </div>

            <div
              style="
                width: 150px;
                height: 36px;
                line-height: 36px;
                border-radius: 30px;
                text-align: center;
                color: #fff;
                font-size: 16px;
                font-weight: 700;
                background-color: #ff6600;
                margin: 80px auto 0;
                cursor: pointer;
              "
              @click="search"
            >
              查 询
            </div>
          </div>
        </div>
      </div>

      <div style="width: 600px; margin: 0 auto 30px">
        <img
          style="margin: 0 auto"
          src="https://atqf.oss-cn-beijing.aliyuncs.com/file/2024-07-19/N_bd04.jpg"
          alt=""
        />
      </div>
    </div>

    <el-dialog
      :visible.sync="dialogVisible"
      :show-close="false"
      :close-on-click-modal="false"
      width="350px"
      center
    >
      <div
        v-if="istitle == false"
        style="
          text-align: center;
          color: #111;
          font-weight: 600;
          font-size: 18px;
          font-family: 微软雅黑;
        "
      >
        www.gx12333.net
      </div>
      <div
        style="
          color: #707070;
          text-align: center;
          font-size: 18px;
          margin-top: 20px;
        "
      >
        {{ msg }}
      </div>
      <div
        style="
          color: #5b6e98;
          text-align: center;
          padding: 15px 0;
          border-top: 1px solid #e6e6e6;
          margin-top: 35px;
          cursor: pointer;
          font-size: 18px;
        "
        @click="submit"
      >
        确定
      </div>
    </el-dialog>

    <!-- 微信弹框 -->
    <el-dialog
      :visible.sync="wxdialogVisible"
      :show-close="false"
      :close-on-click-modal="false"
      width="350px"
      center
    >
      <div
        v-if="iswxtitle == false"
        style="
          text-align: center;
          color: #111;
          font-weight: 600;
          font-size: 18px;
          font-family: 微软雅黑;
        "
      >
        www.gx12333.net
      </div>
      <div
        style="
          color: #707070;
          text-align: center;
          font-size: 18px;
          margin-top: 20px;
        "
      >
        {{ wxmsg }}
      </div>
      <div
        style="
          color: #5b6e98;
          text-align: center;
          padding: 15px 0;
          border-top: 1px solid #e6e6e6;
          margin-top: 35px;
          cursor: pointer;
          font-size: 18px;
        "
        @click="wxSubmit"
      >
        确定
      </div>
    </el-dialog>
  </div>
</template>

<script>
export default {
  data() {
    return {
      codeObj: {},
      yzm: "",
      code: "",
      dialogVisible: false,
      wxdialogVisible: false,
      msg: "",
      wxmsg: "",
      codeVal: "",
      istitle: false,
      iswxtitle: false,
      isAppleDevice: this.checkIfAppleDevice(),
      isWeixin: false,
      iswxpbz: false,
      isSure: false,
    };
  },
  created() {
    let ua = navigator.userAgent.toLowerCase(); //判断是否是微信浏览器
    this.isWeixin = ua.indexOf("micromessenger") != -1; //判断是否
    this.getUrlCode("code");
    this.getCode();
  },
  methods: {
    wxSubmit() {
      this.wxdialogVisible = false;
      this.getCode();
      this.$router.replace("/none");
      // this.$router.push("/from");
    },
    submit() {
      this.dialogVisible = false;
      if (this.isSure == true) {
        this.wxmsg = "";
        this.wxdialogVisible = true;
      }
    },
    checkIfAppleDevice() {
      return /Mac|iPad|iPhone|iPod/.test(navigator.userAgent);
    },
    getUrlCode(variable) {
      var query = window.location.href.substring(1);
      var vars = query.split("?");
      for (var i = 0; i < vars.length; i++) {
        var pair = vars[i].split("=");
        if (pair[0] == variable) {
          this.code = pair[1];
          this.codeVal = this.code;
        }
      }
    },
    search() {
      this.iswxpbz = true;
      if (this.isAppleDevice) {
        if (this.isWeixin == true) {
          if (this.code == "") {
            this.msg = "请输入查询信息。";
            this.dialogVisible = true;
            return;
          }

          if (this.yzm == "") {
            this.msg = "请输入验证码。";
            this.dialogVisible = true;
            return;
          }

          if (this.yzm.toLowerCase() != this.codeObj.exam) {
            this.msg = "验证码错误！";
            this.istitle = false;
            this.dialogVisible = true;
            this.isSure = true;
            this.getCode();
            // this.$router.replace("/none");
            return;
          }

          if (this.code != this.codeVal) {
            this.msg = "查询表单信息失败";
            this.istitle = false;
            this.dialogVisible = true;
            this.isSure = true;
            return;
          }
          this.wxdialogVisible = true;
          return;
        } else {
          if (this.code == "") {
            alert("请输入查询信息。");
            return;
          }

          if (this.yzm == "") {
            alert("请输入验证码。");
            return;
          }

          if (this.yzm.toLowerCase() != this.codeObj.exam) {
            this.getCode();
            this.$router.replace("/none");
            return;
          }

          if (this.code != this.codeVal) {
            this.getCode();
            this.$router.replace("/none");
            return;
          }

          let data = {
            fwm: this.code,
          };
          
          // 转换对象为查询字符串
          const urlParams = new URLSearchParams(data);
          const url = `https://admin.atqf.cn/prod-api/atqf/shbxjfzm/fwm?${urlParams.toString()}`;
          const options = {
            method: "GET", // 请求方法
            headers: {
              "Content-Type": "application/json", // 设置请求头
            },
          };

          fetch(url, options)
            .then((response) => response.json())
            .then((res) => {
              window.location.href = res.data.path;
            })
            .catch((error) => console.error("Error:", error));

          return;
        }
      } else {
        if (this.code == "") {
          this.msg = "请输入查询信息。";
          this.istitle = true;
          this.dialogVisible = true;
          return;
        }

        if (this.yzm == "") {
          this.msg = "请输入验证码。";
          this.istitle = true;
          this.dialogVisible = true;
          return;
        }

        if (this.yzm.toLowerCase() != this.codeObj.exam) {
          this.msg = "验证码错误！";
          this.istitle = true;
          this.dialogVisible = true;
          this.getCode();
          return;
        }

        if (this.code != this.codeVal) {
          this.msg = "查询表单信息失败";
          this.istitle = true;
          this.dialogVisible = true;
          this.getCode();
          return;
        }

        if (
          this.yzm.toLowerCase() == this.codeObj.exam &&
          this.code == this.codeVal
        ) {
          let data = {
            fwm: this.code,
          };
          // 转换对象为查询字符串
          const urlParams = new URLSearchParams(data);
          const url = `https://admin.atqf.cn/prod-api/atqf/shbxjfzm/fwm?${urlParams.toString()}`;
          const options = {
            method: "GET", // 请求方法
            headers: {
              "Content-Type": "application/json", // 设置请求头
            },
          };

          fetch(url, options)
            .then((response) => response.json())
            .then((res) => {
              window.location.href = res.data.path;
            })
            .catch((error) => console.error("Error:", error));

          return;
        }
      }
    },
    getCode() {
      const url = "https://admin.atqf.cn/prod-api/system/yzm/code";
      const options = {
        method: "GET", // 请求方法
        headers: {
          "Content-Type": "application/json", // 设置请求头
        },
      };

      fetch(url, options)
        .then((response) => response.json())
        .then((res) => {
          this.codeObj = res.data;
        })
        .catch((error) => console.error("Error:", error));
    },
  },
};
</script>

<style scoped>
::v-deep .el-dialog--center .el-dialog__body {
  padding-bottom: 0px !important;
}
::v-deep .el-dialog__header {
  padding-top: 0px !important;
}
::v-deep .el-dialog {
  border-radius: 10px !important;
  margin-top: 32vh !important;
}
::v-deep input:focus {
  outline: none !important;
}
.c_text {
  width: 35px;
  height: 35px;
  border-radius: 50%;
  background-color: #ff6600;
  color: #fff;
  line-height: 35px;
  text-align: center;
  font-size: 22px;
  font-weight: 700;
  color: #fff;
}
.c_content {
  width: 150px;
  height: 35px;
  line-height: 35px;
  text-align: left;
  padding-left: 10px;
  background: url(https://atqf.oss-cn-beijing.aliyuncs.com/file/2024-07-19/N_bd02.jpg) 0 100% no-repeat;
  font-size: 16px;
  color: #333;
}
</style>