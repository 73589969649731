<template>
  <!-- 江西 -->
  <div>
    <PdfViewer :url="url"></PdfViewer>
  </div>
</template>

<script>
import PdfViewer from "@/components/jxPdfViewer.vue";

export default {
  components: {
    PdfViewer,
  },
  data: function () {
    return {
      url: "",
      code: null,
    };
  },
  created() {
    this.getUrlCode("code");
    this.getCode();
  },
  methods: {
    getCode() {
      let data = {
        fwm: this.code,
      };
      // 转换对象为查询字符串
      const urlParams = new URLSearchParams(data);
      // const url = `https://admin.atqf.cn/prod-api/atqf/shbxjfzm/fwm?${data}`;
      const url = `https://admin.atqf.cn/prod-api/atqf/jxsShbxgrqxd/fwm?${urlParams.toString()}`;
      const options = {
        method: "GET", // 请求方法
        headers: {
          "Content-Type": "application/json", // 设置请求头
        },
      };

      fetch(url, options)
        .then((response) => response.json())
        .then((res) => {
          this.url = res.data.path;
        })
        .catch((error) => console.error("Error:", error));

      return;
    },
    getUrlCode(variable) {
      var query = window.location.href.substring(1);
      var vars = query.split("?");
      for (var i = 0; i < vars.length; i++) {
        var pair = vars[i].split("=");
        if (pair[0] == variable) {
          this.code = pair[1];
        }
      }
    },
  },
};
</script>
